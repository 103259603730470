<template>
    <v-sheet>
        <v-col>
            <v-simple-table>
                <tbody>
                    <tr v-for="question in questions" :key="question.id">
                        <td>{{question.title}}</td>
                        <td>{{question.profile_field}}</td>
                        <td><v-btn @click="nullify(question.id)">Set to null</v-btn></td>
                        <td><v-btn :href="`/data-tools/for/${question.id}`">Find Responses</v-btn></td>
                    </tr>
                </tbody>
            </v-simple-table>
        </v-col>
        <v-col></v-col>
    </v-sheet>
</template>


<script>
export default {
    name: "ResponsesToProfileInfo",
    computed: {
        questions() {
            let questions = Object.values(this.$store.state.questions.data);
            return questions.filter( q => q.profile_field )
        }
    },
    methods: {
        nullify(id){
            this.$store.dispatch("questions/patch", {id, profile_field: null })
        }
    },
    created(){
        this.$store.dispatch("questions/fetchAndAdd", {clauses: {limit: -1}})
    }
}
</script>

